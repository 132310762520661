import { Customer } from '../interfaces/customer';
import { Item } from '../interfaces/item';

export function getThumbnailImageURL(item: Item) {
  return getMainImage(item, 'sm');
}

export function getMainImage(item: Item, size: string | null) {
  if (item.type === 'DiscontinuedCarvedItem') {
    return null;
  }

  if (item.type === 'Display' || item.type === 'Program') {
    return getImageURL(item, null, size);
  }

  return getImageURL(item, 'P1', size);
}

export function getImageURL(item: Item, suffix: string | null, size: string | null) {
  const finalSuffix = suffix ? `_${suffix}` : '';
  const finalSize = size ?? 'lg';
  return `https://bmstoragenew.blob.core.windows.net/webimages/${finalSize}/${getAssetKey(item.styleNumber)}${finalSuffix}.jpg`;
}

export function getAssetKey(itemStyle: string) {
  if (itemStyle.slice(-8).match(/1[048]K[WYR]1[048]K[WYR]/)) {
    return itemStyle.slice(0, -8)
  }
  if (itemStyle.slice(-6).match(/1[048]K[WYR]PT/)) {
    return itemStyle.slice(0, -6);
  }
  if (itemStyle.slice(-6).match(/1[048]KRWY/)) {
    return itemStyle.slice(0, -6);
  }
  if (itemStyle.slice(-5).match(/1[048]KWY/)) {
    return itemStyle.slice(0, -5);
  }
  if (itemStyle.slice(-5).match(/1[048]KR[WY]/)) {
    return itemStyle.slice(0, -5);
  }
  if (itemStyle.slice(-4).match(/1[048]KW/)) {
    return `${itemStyle.slice(0, -4)}W`;
  }
  if (itemStyle.slice(-4).match(/1[048]KY/)) {
    return `${itemStyle.slice(0, -4)}Y`;
  }
  if (itemStyle.slice(-4).match(/1[048]KR/)) {
    return `${itemStyle.slice(0, -4)}R`;
  }
  if (itemStyle.slice(-3).match(/1[048]K/)) {
    return itemStyle.slice(0, -3);
  }
  if (itemStyle.slice(-2).match(/(PT)$/)) {
    return `${itemStyle.slice(0, -2)}W`;
  }
  if (itemStyle.slice(-2).match(/(PD|SV)$/)) {
    return `${itemStyle.slice(0, -5)}W`;
  }
  return itemStyle;
}
